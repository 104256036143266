import axios from 'axios'
import vari from '@/app/shared/utils/variables'
import router from '@/router'
import modals from '@/app/commons/utils/modals'

const actions = {
  /** Obtiene el objeto del perfil que ha iniciado sesion */
  async getProfile(context) {
    try {
      const response = await axios.get(`${vari.UHR}/user`)
      const profile = response.data
      context.commit('SET_PROFILE', profile)
      await context.dispatch('saveTypeUser', profile.type)
    } catch (error) {
      console.error('[profileModule][getProfile]', error)
    }
  },
  /**
   * Solicita la actualizacion del estado del usuario
   * @param {vuex}    context
   * @param {Object}  dataStatus objeto de estado
   * @param {String}  dataStatus.status nombre del estado
   * @param {Boolean} dataStatus.force Definir si se fuerza a insertar el estado entrante, sino, se toma el que existe en el storage
   */
  async updateUserStatus(context, { status, force = false }) {
    try {
      let startedIn = new Date()
      if (force) {
        await context.dispatch('saveStatusUser', { status, startedIn })
        // await axios.put(`${vari.UHR}/user/status`, { status, startedIn })
        // console.log('[profileModule][updateUserStatus] force')
      } else if (!context.state.profile.status) {
        await context.dispatch('saveStatusUser', { status, startedIn })
        // await axios.put(`${vari.UHR}/user/status`, { status, startedIn })
        // console.log('[profileModule][updateUserStatus] un status')
      } else {
        await context.dispatch('getStatusUser')
        status = context.state.profile.status
        const statusObj = JSON.parse(localStorage.getItem('status'))
        startedIn = new Date(statusObj.startedIn)
        await context.dispatch('saveStatusUser', {
          status: statusObj.status,
          startedIn: startedIn,
        })
        // console.log('[profileModule][updateUserStatus] current status')
      }
    } catch (error) {
      console.error('[profileModule][updateUserStatus]', error)
    }
  },
  /**
   * Obtiene el objeto del perfil remoto de un agente al que se está vosualizando
   * @param {*} context
   * @param {String} userId userId del agente
   */
  async getProfileRemote(context, userId) {
    try {
      if (!router.currentRoute.meta.is_monitor) return

      const response = await axios.get(`${vari.UHR}/admin/account/${userId}`, {
        validateStatus() {
          return true
        },
      })
      if (response.status !== 200)
        throw { success: false, result: response.status }
      const profile = response.data
      context.commit('SET_PROFILE_REMOTE', profile)
      return { success: true, result: profile }
    } catch (error) {
      // console.error("[profileModule][getProfileRemote]", error)
      return error
    }
  },
  /**
   * Obtener las novedades
   * @param {*} context
   */
  getNews(context, type) {
    if (router.history.current.name === 'password') return // En /password no debe mostrar las noticias o novedades

    axios
      .get(`${vari.UHR}/news/${type}`)
      .then((response) => {
        response.data.forEach((oneNews) => context.commit('ADD_NEWS', oneNews))
      })
      .catch((error) => {
        // console.error("[profileModule][getNews]", error)
        return error
      })
  },
  /**
   * Marcar la novedad como leído
   * @param {vuex}   context
   * @param {Object} args
   * @param {String} args.newsId
   * @param {String} args.type
   * @param {String} args.channel_company_id
   */
  readNews(context, { newsId, type, channel_company_id }) {
    axios
      .put(`${vari.UHR}/news/${type}/${newsId}`, {
        channel_company_id: channel_company_id,
      })
      .then(() => {
        context.commit('REMOVE_NEWS_EL', newsId)
      })
      .catch((error) => {
        // console.error("[profileModule][getNews]", error)
        return error
      })
  },

  /**
   * Obtener las alertas para mostrarlas mediante modals
   * @param {*} context
   * @return {void}
   */
  async getAlerts(context) {
    try {
      if (
        router.history.current.name === 'password' ||
        router.history.current.meta.is_monitor
      )
        return // En estas rutas no debe ocurrir esto

      const response = await axios.get(`${vari.UHR}/modals`)
      const resultApi = response.data

      // Si hay alertas enviar modal segun su código
      if (resultApi.data) {
        // const codeModal = 'CREDIT_CARD_PAYMENT_ERROR'
        const codeModal = resultApi.data.code
        const idModal = resultApi.data._id

        // call mutation
        context.commit('SET_BASIC_MODAL', {
          ...modals[codeModal],
          id: idModal,
        })
      }
    } catch (error) {
      // console.error("[profileModule][getAlerts]", error)
      return error
    }
  },

  /**
   * Obtener las alertas para mostrarlas mediante modals
   * @param {*} context
   * @param {ObjectId} modalId
   * @return {Object}
   */
  async setReadAlerts(context, modalId) {
    try {
      const response = await axios.put(`${vari.UHR}/modals/${modalId}/read`)
      const resultApi = response.data
      return resultApi
    } catch (error) {
      // console.error("[profileModule][setReadAlerts]", error)
      return error
    }
  },
}

export default actions
