<template>
  <a-modal
    v-model="visible"
    :footer="null"
    :bodyStyle="bodyStyle"
    centered
    :width="modalWidth"
    :maskClosable="false"
    @cancel="handleActionCancel"
  >
    <template v-if="data.layout === 'columns'">
      <a-row
        class="modal-contain"
        style="{'width': '793px'}"
        type="flex"
        align="middle"
      >
        <a-col :span="12" class="first-column">
          <h2
            class="heading-h2 color-gray-dark-900"
            style="margin-bottom: 32px"
          >
            <i v-if="titleIconsStart.length > 0" class="title-icon start">
              <i v-for="titleIcon in titleIconsStart" :key="titleIcon._id">
                <img
                  v-if="titleIcon.graphIcon === 'image'"
                  :src="titleIcon.src"
                />
                <i
                  v-else-if="titleIcon.graphIcon === 'draw'"
                  v-html="titleIcon.src"
                ></i>
              </i>
            </i>
            {{ data.title }}
            <i v-if="titleIconsEnd.length > 0" class="title-icon end">
              <i v-for="titleIcon in titleIconsEnd" :key="titleIcon._id">
                <img
                  v-if="titleIcon.graphIcon === 'image'"
                  :src="titleIcon.src"
                />
                <i
                  v-else-if="titleIcon.graphIcon === 'draw'"
                  v-html="titleIcon.src"
                ></i>
              </i>
            </i>
          </h2>
          <!--graph ilustration puede tomar los valores de draw, image, lottie-->
          <!--ilustracion SVG-->
          <template v-if="data.graphIlustration === 'draw'">
            <div v-html="data.ilustration"></div>
          </template>
          <!--ilustracion SRC-->
          <template v-else-if="data.graphIlustration === 'image'">
            <img
              :src="data.ilustration"
              :alt="data.ilustration"
              style="cursor: auto"
            />
          </template>
          <!--ilustracion LOTTIE-->
          <lottie-vue-player
            v-else-if="data.graphIlustration === 'lottie'"
            :src="data.ilustration"
            autoplay
            loop
            mode="normal"
          />
        </a-col>
        <a-col :span="12" class="second-column">
          <template v-for="item in data.news">
            <div class="feature-description" :key="item._id">
              <!--por svg-->
              <template v-if="item.graphIcon === 'draw'">
                <div v-html="item.icon"></div>
              </template>
              <!--por ruta-->
              <template v-else>
                <img :src="item.icon" style="cursor: auto" />
              </template>
              <div class="feature-contain">
                <div class="feature-stretch">
                  <h6 class="heading-h6 color-gray-dark-900">
                    {{ item.subtitle }}
                  </h6>
                  <div>
                    <a-tag v-if="item.tag" :color="item.tag.color">
                      {{ item.tag.text }}
                    </a-tag>
                  </div>
                </div>
                <p
                  v-html="item.description"
                  class="body-2 color-gray-dark-800"
                  style="margin-bottom: 0"
                ></p>
              </div>
            </div>
          </template>
        </a-col>
      </a-row>
      <a-row v-if="data.extraInfo">
        <div
          class="body-2 color-gray-13 extraInfo"
          v-html="data.extraInfo"
        ></div>
      </a-row>
      <div class="modal-footer" :class="{ 'margin-off': !!data.extraInfo }">
        <a-button
          type="primary"
          style="width: 148px"
          size="large"
          @click="handleCloseModal"
        >
          Genial
        </a-button>
      </div>
    </template>
    <template v-if="data.layout === 'list'">
      <a-row class="modal-contain">
        <a-col :span="24" class="align-center">
          <div
            v-if="data.graphIlustration === 'draw'"
            v-html="data.ilustration"
          ></div>
          <!--ilustracion LOTTIE-->
          <lottie-vue-player
            v-else-if="data.graphIlustration === 'lottie'"
            :src="data.ilustration"
            autoplay
            loop
            mode="normal"
          />
          <h5 class="heading-h5 list-title">
            {{ data.title }}
          </h5>
        </a-col>
        <a-col :span="24" class="align-center">
          <ul class="list-items">
            <li
              v-for="item in data.news"
              :key="item._id"
              class="item list-style-none"
            >
              <span class="icon-check">✅</span> {{ item.description }}
            </li>
          </ul>
        </a-col>
      </a-row>
      <div class="modal-footer">
        <a-button @click="handleGoToChannels">Ir al canal de WhatsApp</a-button>
        <a-button
          v-if="goToWorkspace"
          type="primary"
          style="margin-left: 12px"
          @click="handleGoToWorkspace"
          >Ir a worskpace</a-button
        >
      </div>
    </template>
    <template v-if="data.type === 'welcome'">
      <content-welcome
        :visible="visible"
        :names="profile.names"
        :news="data.news"
        :textFooterContent="data.textFooterContent"
        :title="data.title"
        :buttons="data.buttons"
      />
      <div class="modal-footer">
        <a class="link-welcome regular-16" @click="handleContinue">{{
          data.textFooterModal
        }}</a>
      </div>
    </template>
    <template v-if="data.layout === 'basic'">
      <basic-content
        :title="data.title"
        :subtitle="data.news[0].subtitle"
        :description="data.news[0].description"
        :graphIlustration="data.graphIlustration"
        :ilustration="data.ilustration"
        :showFirstIlustration="data.showFirstIlustration"
      />
      <div class="modal-center-footer">
        <a-button type="primary" size="large" @click="handleCloseModal">{{
          data.buttonCloseText || 'Entendido'
        }}</a-button>
      </div>
    </template>
    <template v-if="data.layout === 'fullImage'">
      <full-image-content :data="data" @onClose="handleActionCancel" />
    </template>
  </a-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import ContentWelcome from '@/app/commons/components/organisms/ContentWelcome'
import BasicContent from '@/app/commons/components/organisms/BasicContent'
import FullImageContent from '@/app/commons/components/organisms/FullImageContent'

export default {
  name: 'ModalNewFeatures',
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      require: false,
      default: 'Yupi!, there are news here',
    },
    data: {
      type: Object,
      required: false,
    },
    goToWorkspace: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ContentWelcome,
    BasicContent,
    FullImageContent,
  },
  data: () => ({
    bodyStyle: {
      minHeight: '240px',
    },
  }),
  created() {
    // cambiar esta condicion a su version positiva porque afecta a las futuras news. Debería validarse con el ===
    if (this.data.type !== 'welcome' && this.data.layout !== 'fullImage') {
      this.bodyStyle['padding-top'] = '60px'
      this.bodyStyle['padding-right'] = '40px'
      this.bodyStyle['padding-left'] = '40px'
    } else {
      this.bodyStyle['padding'] = '0px'
      if (
        ['admin', 'admin_agent', 'master_agent'].includes(this.profile.type)
      ) {
        const currentRoute = this.$route.name
        if (currentRoute !== 'channels') this.$router.push({ name: 'channels' })
      }
    }
  },
  computed: {
    ...mapGetters(['profile']),

    titleIconsStart() {
      if (!this.data.titleIcons) return []
      return this.data.titleIcons.filter((ti) => ti.position === 'start')
    },
    titleIconsEnd() {
      if (!this.data.titleIcons) return []
      return this.data.titleIcons.filter((ti) => ti.position === 'end')
    },
    modalWidth() {
      if (this.data.type === 'welcome') return '900px'
      if (this.data.layout === 'basic') return '58em'
      else if (this.data.layout === 'fullImage') return 'auto'
      return '800px'
    },
  },
  methods: {
    // cierra el modal
    handleCloseModal() {
      if (this.data.evaluationType === 'channel')
        this.$emit(
          'onClose',
          this.data._id,
          'evaluation',
          this.data.evaluationValue
        )
      else if (this.data.type === 'welcome')
        this.$emit('onClose', this.data._id, 'welcome')
      else this.$emit('onClose', this.data._id, 'feature')
    },
    handleGoToChannels() {
      this.handleCloseModal()
      if (this.data.channelCompanyId)
        this.$router.push({
          name: 'channel',
          params: { channel: 'whatsapp' },
          query: { channelId: this.data.channelCompanyId },
        })
      else
        return this.$router.push({
          name: 'my-channels',
        })
    },
    handleGoToWorkspace() {
      this.handleCloseModal()
      this.$router.push({ name: 'workspace' })
    },
    handleContinue() {
      this.handleCloseModal()
      this.$emit('onContinue')
    },
    handleActionCancel() {
      if (this.data.type === 'welcome') this.handleContinue()
      else this.handleCloseModal()
    },
  },
}
</script>

<style lang="sass" scoped>
.color-gray-13
  color: $gray_13
.color-gray-dark-900
  color: $gray_dark_900
.color-gray-dark-800
  color: $gray_dark_800
.align-center
  display: flex
  flex-direction: column
  align-items: center
.list-style-none
  list-style: none
.extraInfo
  text-align: center
  margin: 20px 0 0 0

.modal-contain
  .second-column
    .feature-description
      display: flex
      flex-direction: row
      align-items: flex-start
      margin-bottom: 20px
      .feature-contain
        margin-left: 8px
        .feature-stretch
          display: flex
          justify-content: space-between
      &:last-child
        margin-bottom: 0
  .title-icon
    height: 28px
    i
      img, svg
        height: 28px
        width: 28px
    &.start
      i
        padding: 0 0 0 8px
        &:first-child
          padding: 0
    &.end
      i
        padding: 0 8px 0 0
        &:last-child
          padding: 0
.modal-footer
  margin-top: 40px
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center
  padding: 20px
  border-top: 1px solid #E8E8E8
  &.margin-off
    margin: 0
.list-title
  margin-top: 24px
  margin-bottom: 16px
  text-align: center
.list-items
  padding-left: 0
  .item
    margin-bottom: 8px
    .icon-check
      margin-right: 8px
.modal-center-footer
  display: flex
  align-items: center
  justify-content: center
  margin-bottom: 36px
.link-welcome
  color: $gray_7
  text-decoration: underline
</style>
