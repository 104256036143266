<template>
  <a-row
    class="container width-full flex-no-wrap"
    type="flex"
    justify="space-between"
    align="middle"
  >
    <!--Etiquetas para desktop-->
    <template v-if="!active_breakpoint.is_mobile">
      <div
        ref="container_tags"
        class="container__tags display-flex flex-grow flex-wrap"
      >
        <a-tag
          v-for="tag in sortTags({ tags: tagsSelectedFormatted })"
          :key="`tag${tag.tagId}`"
          :ref="`tag${tag.tagId}`"
          :class="{
            hidden: !tag.visible && !tag.wasChecked,
            'display-none': !tag.visible && tag.wasChecked,
          }"
          :color="tag.color"
        >
          {{ verifyRender(tag.tagId) }} {{ tag.title }}</a-tag
        >
        <!--Popover del listado de etiquetas seleccionadas-->
        <popover-tags-list
          v-if="!!aditional"
          :data-source="tagsNoVisible"
          placement="right"
        >
          <a-tag style="border-radius: 12px">+{{ aditional }}</a-tag>
        </popover-tags-list>
      </div>
    </template>
    <!--Etiquetas para mobile-->
    <template v-else>
      <div
        ref="container_tags"
        class="container__tags display-flex flex-grow flex-wrap align--center"
      >
        <color-pick
          v-for="tag in sortTags({ tags: tagsSelectedFormatted })"
          :key="`tag${tag.tagId}`"
          :ref="`tag${tag.tagId}`"
          class="mrg-right-12"
          :class="{
            hidden: !tag.visible && !tag.wasChecked,
            'display-none': !tag.visible && tag.wasChecked,
          }"
          :color="tag.color"
          :pick-styles="pickStyles"
          :master-styles="masterStyles"
          >{{ verifyRender(tag.tagId, 12) }}</color-pick
        >
        <a-tag v-if="!!aditional" style="border-radius: 12px"
          >+{{ aditional }}</a-tag
        >
      </div>
    </template>
    <!-- Poner tags -->
    <popover-tags-checkboxes
      v-if="addTags"
      @onCheck="handleCheckTag"
      :data-source="dataSource"
    >
      <a class="mrg-left-12 container__add-link text--right">
        <a-icon type="tag" class="mrg-right-2" />
        <span>Añadir etiquetas</span>
      </a>
    </popover-tags-checkboxes>
  </a-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PopoverTagsCheckboxes from '@/app/shared/components/molecules/PopoverTagsCheckboxes'
import PopoverTagsList from '@/app/shared/components/molecules/PopoverTagsList'
import ColorPick from '@/app/shared/components/pickers/ColorPick'
import sortMixin from '@/app/shared/mixins/sort'

export default {
  name: 'TagsContainer',
  props: {
    tagsSelected: {
      type: Array,
      default: () => [],
      required: false,
    },
    addTags: {
      type: Boolean,
      required: false,
      default: false,
    },
    lineId: {
      type: String,
      required: false,
    },
  },
  components: {
    PopoverTagsCheckboxes,
    PopoverTagsList,
    ColorPick,
  },
  data: () => ({
    masterStyles: {
      height: '12px',
    },
    pickStyles: {
      width: '12px',
      height: '12px',
      'border-radius': ' 8px',
    },
    aditional: 0,
    tagsSelectedFormatted: [],
    allWidths: 0, // la suma de todos los anchos de los tags
  }),
  mounted() {
    this.tagsSelectedFormatted = this.formatTagsSelected()
    window.addEventListener('resize', this.reloadAll)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.reloadAll)
  },
  mixins: [sortMixin],
  watch: {
    tagsSelected() {
      this.reloadAll()
    },
  },
  computed: {
    ...mapGetters(['simple_tags', 'ticketSelected', 'active_breakpoint']),

    /**
     * Etiqueta que no están incluidas en simple_tags
     * @returns {Object[]} tags
     */
    tagsSelectedNoIncludedInSimple() {
      const simpleTags = this.simple_tags.map((simpleTag) => simpleTag._id)
      const tags = this.tagsSelected.filter(
        (tagSelected) => !simpleTags.includes(tagSelected.tagId)
      )
      const tagsFormatted = tags.map((tag) => {
        tag._id = tag.tagId
        tag.allLines = true
        tag.unassigned = true
        return tag
      })
      return tagsFormatted
    },

    /**
     * Formatea a nuevas propiedades los tags generales
     * @return {Object[]}
     */
    dataSource() {
      if (!this.lineId) return
      // agrega las etiquetas historicas a las asignadas, solo si no se repiten
      const allTags = [
        ...this.simple_tags,
        ...this.tagsSelectedNoIncludedInSimple,
      ]
      console.log('allTags', allTags)
      // filtra las etiquetas asignadas a todas las colas y a las colas de los tickets seleccionados
      const filteredTags = allTags.filter(
        (tag) => tag.allLines || tag.linesIds.includes(this.lineId)
      )
      return filteredTags.map((tag) => {
        // encontrar si ya esta seleccionado o no
        const isChecked = [...this.tagsSelected].find(
          (tagSelected) => tagSelected.tagId === tag._id
        )
        const newObj = { ...tag }
        newObj.checked = !!isChecked
        newObj.key = tag._id
        return newObj
      })
    },
    /**
     * @return {Object[]} tags que no son visibles
     */
    tagsNoVisible() {
      return this.tagsSelectedFormatted.filter((tag) => !tag.visible)
    },
  },
  methods: {
    ...mapActions(['saveTagTicket']),

    /**
     * @param {Object} tag Objeto del tag a guardar
     * @param {String} tag._id Id de la etiqueta
     */
    async putTags(tag, checked) {
      if (!this.ticketSelected)
        return console.error('[tagsContained, putTags], no ticketSelected')

      await this.saveTagTicket({
        idTicket: this.ticketSelected._id,
        tag,
        checked,
      })
    },
    /**
     * Escucha cuando se chekea un checkbox
     * @param {Boolean} checked
     * @param {String} key
     */
    handleCheckTag(checked, key) {
      const tag = this.dataSource.find((tag) => tag._id === key)
      this.putTags(tag, checked)
    },
    /**
     * Formatea un nuevo valor los tags seleccionados del ticket
     */
    formatTagsSelected() {
      return [...this.tagsSelected].map((tagSelected, index) => {
        const newObj = { ...tagSelected }
        // si es el primer elemento
        if (index === 0) newObj.visible = true
        // los demas elementos, estarán no visibles en la primera renderizada
        else {
          newObj.visible = false
        }
        return newObj
      })
    },
    /**
     * Calcula si la etiqueta debe ser visible o no
     * @param {String} tagId
     * @param {Number} margin - margen entre etiquetas
     */
    calculateTag(tagId, margin = 12) {
      this.$nextTick(() => {
        const tagSeletected = this.tagsSelectedFormatted.find(
          (tag) => tag.tagId === tagId
        )
        // si no se ha encontrado al tag
        if (!tagSeletected) {
          this.aditional = this.aditional - 1
          return
        }
        if (tagSeletected.wasChecked) return
        const containerTags = this.$refs.container_tags

        const error = 78
        const widthContainerTags = containerTags.offsetWidth - error

        const widthBadge = 42
        const marginTag = margin

        const tagElement = this.$refs[`tag${tagSeletected.tagId}`][0]
        const tagWidth = tagElement.$el.offsetWidth
        if (
          this.allWidths + widthBadge + tagWidth < widthContainerTags &&
          !this.aditional
        ) {
          tagSeletected.visible = true
          this.allWidths += tagWidth + marginTag
        } else {
          tagSeletected.visible = false
          this.aditional += 1
        }

        tagSeletected.wasChecked = true

        const founIndex = this.tagsSelectedFormatted.findIndex(
          (tag) => tag.tagId === tagId
        )
        if (founIndex !== -1)
          this.tagsSelectedFormatted[founIndex] = tagSeletected
      })
    },
    /**
     * Verifica si el tag se ha renderizado
     * @param {String} tagId
     */
    verifyRender(tagId) {
      this.calculateTag(tagId)
      return
    },
    /**
     * Resetea los valores usa
     */
    resetAll() {
      this.aditional = 0
      this.allWidths = 0
    },
    /**
     * Vuelve a cargar todos los datos iniciales
     */
    reloadAll() {
      this.resetAll()
      this.tagsSelectedFormatted = this.formatTagsSelected()
    },
  },
}
</script>

<style lang="sass" scoped>
.hidden
  visibility: hidden
.display-none
  display: none
.container
  margin-top: -1px
  &__tags
    @include xs
      padding: 12px 0px 12px 20px
    @include sm
      padding: 12px 0px 12px 20px
    @include md
      padding: 12px 0px 12px 20px
  &__add-link
    white-space: nowrap
    @include xs
      padding: 12px 20px 12px 0px
    @include sm
      padding: 12px 20px 12px 0px
    @include md
      padding: 12px 20px 12px 0px
</style>
